import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import { HTMLContent } from '../components/Content';
import EventTimeline from '../components/EventTimeline';

export default class EventList extends React.Component {
  render() {
    const { data } = this.props;
    const { html, frontmatter } = data.codaPage;
    const { edges: futureEvents } = data.futureEvents || [];
    const { edges: pastEvents } = data.pastEvents || [];

    return (
      <Layout>
        <section className="section">
          {frontmatter.title && false ? (
            <h2 className="title">{frontmatter.title}</h2>
          ) : null}
          {html && false ? (
            <HTMLContent className="content" content={html} />
          ) : null}
          <EventTimeline
            events={futureEvents}
            className="timeline is-centered-desktop"
          />
        </section>
        <section className="section">
          <h4 className="title is-4">Menneet tapahtumat:</h4>
          <ul>
            {pastEvents.map(({ node: event }) => (
              <li key={event.id} className="is-size-6 has-text-weight-bold">
                <span>{event.frontmatter.start_date}</span>{' '}
                <Link to={`/${event.fields.slug}`}>
                  {event.frontmatter.title}
                </Link>
              </li>
            ))}
          </ul>
        </section>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query EventListQuery($id: String!) {
    codaPage(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    futureEvents: allEvent(
      sort: { order: ASC, fields: [frontmatter___start_date] }
      filter: {
        frontmatter: { future: { eq: true } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            price
            over24h
            primary
            cancelled
            start_year: start_date(formatString: "YYYY", locale: "fi")
            start_month: start_date(formatString: "MMM", locale: "fi")
            start_dow: start_date(formatString: "dd", locale: "fi")
            start_date(formatString: "l", locale: "fi")
            start_time: start_date(formatString: "LT", locale: "fi")
            end_date(formatString: "l", locale: "fi")
            end_time: end_date(formatString: "LT", locale: "fi")
          }
        }
      }
    }
    pastEvents: allEvent(
      sort: { order: DESC, fields: [frontmatter___start_date] }
      filter: {
        frontmatter: { future: { eq: false } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            start_date(formatString: "l", locale: "fi")
          }
        }
      }
    }
  }
`;
